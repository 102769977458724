










import { Component, Vue, Prop } from "vue-property-decorator";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
const ImageFormat = () => import("@/components/ImageFormat.vue");
// @ is an alias to /src

@Component({
  components: {
    swiper,
    swiperSlide,
    ImageFormat
  }
})
export default class Swiper extends Vue {
  swiperOption: any = {
    // Enable lazy loading
    // // lazy: true,
    // loop: true,
    allowTouchMove: false,
    effect: "fade",
    speed: 3000,
    autoplay: {
      delay: 500
    }
  };
  $refs!: {
    swiper: any;
    // swiperButtonPrev: any
    // swiperButtonNext: any
  };
  @Prop()
  slides!: [];

  get swiper() {
    return this.$refs.swiper.swiper;
  }
  mounted() {
    // current swiper instance
    // 然后你就可以使用当前上下文内的swiper对象去做你想做的事了
    // this.swiper.slideTo(3, 1000, false)
  }
}
